<template>
  <div>
    <div v-if="isLoaded" class="card">

      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
        <form @submit.prevent="handleSubmit(submitForm)">

          <!-- Name -->
          <div class="max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.name')" rules="required|max:255" v-slot="{errors}">
              <label class="form__label">{{ $t('pages.culture_create.fields.name') }}<Required/></label>
              <input v-model="form.name" class="form__input w-full" type="text">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Cultivation type (outside / inside) -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.cultureType') }}<Required/></label>
            <input id="radio-outside" type="radio" value="1" v-model.number="form.outside">
            <label for="radio-outside" class="text-gray-600"> {{ $t('pages.culture_create.fields.outside') }}</label>

            <input id="radio-inside" class="ml-4" type="radio" value="0" v-model.number="form.outside">
            <label for="radio-inside" class="text-gray-600"> {{ $t('pages.culture_create.fields.inside') }}</label>
          </div>

          <!-- Type - Variety -->
          <div class="mt-4 max-w-sm flex">

            <div class="w-full">
              <label class="form__label">{{ $t('pages.culture_create.fields.variety') }}<Required/></label>
              <select v-model.number="form.varietyId" class="form__input cursor-pointer">
                <optgroup v-for="cultureType in cultureTypes" :key="cultureType.id" :label="$t('backend_trans_keys.' + cultureType.typeTransKey)">
                  <option v-for="variety in cultureType.varieties" :key="variety.id" :value="variety.id">{{ $t('backend_trans_keys.' + variety.varietyTransKey) }}</option>
                </optgroup>
              </select>
            </div>

            <div  class="w-full">
              <ValidationProvider :name="$t('pages.culture_create.fields.nouaisonDate')" v-slot="{errors}">
                <label class="form__label">{{ $t('pages.culture_create.fields.nouaisonDate') }}</label>
                <input v-model="form.nouaisonDate" class="form__input w-full" type="date">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

          </div>

          <!-- Interactive map -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.interactiveMapType.title') }}<Required/></label>
            <select v-model.number="form.interactiveMapType" class="form__input cursor-pointer">
              <option value="0">{{ $t('pages.culture_create.fields.interactiveMapType.none') }}</option>
              <option value="1">{{ $t('pages.culture_create.fields.interactiveMapType.geo') }}</option>
              <option value="2">{{ $t('pages.culture_create.fields.interactiveMapType.custom') }}</option>
            </select>

            <!-- Upload custom map file -->
            <div v-if="form.interactiveMapType === 2" class="mt-4 flex flex items-center">
              <!-- Full Drop zone -->
              <ValidationProvider name="fileDrop" v-slot="{errors}">
                <file-drop-zone @onSuccessUploadFile="onSuccessUploadFile" @onError="onFileDropError" @onDeleteUploadFile="onDeleteUploadFile" />
                <!-- Error -->
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <!-- Create button -->
          <button class="mt-6 btn" :class="{ 'btn--success': !invalid,'btn--disabled': invalid }"><i class="fas fa-check mr-2"></i>{{ $t('pages.culture_create.create') }}</button>
        </form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import {API_CULTURE_TYPES_INDEX, API_CULTURES_STORE} from "../../../utils/constants";
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import FileDropZone from "../../../components/forms/FileDropZone";
console.log("Salut je passe par là, c'est cool !")   //AFFICHAGE

export default {
  name: "CultureCreate",
  components: {FileDropZone, Required},
  props: {
    farmerId: {
      type: [Number, String],
      required: false // Possibly not here if we come directly from the link (/cultures/create)
    }
  },
  data: function() {
    return {
      isLoaded: false,
      form: {
        farmerId: this.farmerId,
        name: null,
        nouaisonDate: null,
        outside: 1,
        varietyId: -1,
        interactiveMapType: 0,
        customMapFile: null
      },
      cultureTypes: [],
    }
  },
  mounted() {
    // In case of we came directly from the URL (cultures/create) => we set the farmerID as the current logged in farmer
    if(this.farmerId === undefined){
      this.form.farmerId = this.$store.state.user.id
    }
    console.log("Salut je passe par là !") 
    this.loadVarieties()
  },
  methods: {
    /**
     * Load all culture types with their varieties for the select input
     */
    loadVarieties: function(){
      this.isLoaded = false
      apiGetRequest(API_CULTURE_TYPES_INDEX)
          .then((res) => {
            this.cultureTypes = res.data
             //If we have minimum one culture type with one variety, select this one as default choice
            if(this.cultureTypes.length > 0 && this.cultureTypes[0].varieties.length > 0){
              this.form.varietyId = this.cultureTypes[0].varieties[0].id
            }
            this.isLoaded = true
          })
    },
    onFileDropError: function(error){
      this.form.customMapFile = null

      const errors = this.$refs.form.errors
      errors['fileDrop'] = error.message
      this.$refs.form.setErrors(errors)
    },
    onSuccessUploadFile: function (file) {
      // Reset old file drop error
      const errors = this.$refs.form.errors
      errors['fileDrop'] = null
      this.$refs.form.setErrors(errors)

      this.form.customMapFile = file
    },
    onDeleteUploadFile: function(){
      this.form.customMapFile = null
    },
    /**
     * Submit the form as a form-data to be able to send correctly the file (in case of custom interactive map chosen)
     */
    submitForm: function() {
      if(this.form.interactiveMapType === 2 && this.form.customMapFile === null){
        const errors = this.$refs.form.errors
        errors['fileDrop'] = this.$t('pages.culture_create.errorMapFileRequired')
        this.$refs.form.setErrors(errors)
      }else{
        const formData = new FormData()
        formData.append('farmerId', this.form.farmerId)
        formData.append('name', this.form.name)
        formData.append('outside', this.form.outside)
        formData.append('varietyId', this.form.varietyId)
        if(this.form.nouaisonDate !== null){
          formData.append('nouaisonDate', this.form.nouaisonDate)
        }
        formData.append('interactiveMapType', this.form.interactiveMapType)
        if(this.form.interactiveMapType === 2){
          formData.append('customMapFile', this.form.customMapFile, this.form.customMapFile.name)
        }

        apiPostRequest(API_CULTURES_STORE, formData, true)
            .then(() => {
              // Redirect to right route and display a success message
              const successMsg = this.$t('pages.culture_create.success', {name: this.form.name})
              displayAlertSuccess(successMsg)
              if(this.$store.getters.userIsAdmin){
                this.$router.push({name: "farmer_show", params: {id: this.form.farmerId}})
              }else{
                this.$router.push({name: "culture_index"})
              }
            })
            .catch((e) => {
              // Specific mimetype backend check error
              if(e.response.data.fields.mapFile === "mimeType"){
                const errors = this.$refs.form.errors
                errors['fileDrop'] = this.$t('components.fileDropZone.errors.supportedTypes')
                this.$refs.form.setErrors(errors)
              }
              // Specific file size backend check error
              else if(e.response.data.fields.mapFile === "fileSize"){
                const errors = this.$refs.form.errors
                errors['fileDrop'] = this.$t('components.fileDropZone.errors.sizeLimit')
                this.$refs.form.setErrors(errors)
              }
            })
      }
    },
  }
}
</script>

<style scoped>
</style>
