<template>
  <div>
    <!-- Add material (only admin can do this)-->
    <div v-if="this.$store.getters.userIsAdmin">
      <button @click="toggleAddMaterialModal" class="mb-4 btn btn--success">
        <i class="fas fa-plus mr-2"></i>{{ $t('pages.inventory.addMaterial') }}
      </button>
      <AddMaterialModal @newTransmitterCreated="newTransmitterCreated" @newSensorCreated="newSensorCreated" :visible="addMaterialModal.isVisible" :toggle="toggleAddMaterialModal" :user-id="userId"/>
    </div>

    <!-- Conteneur principal pour aligner les sections côte à côte -->
    <div class="inventory-wrapper">
      <!-- All transmitters -->
      <div v-if="isLoaded.transmitters" class="inventory-container">
        <div class="card">
          <h2 class="card__title">
            <span class="title-text">Tous les dispositifs émetteurs </span>
            <span class="title-count">({{ transmittersTable.data.length }})</span>
          </h2>
          <h3 v-html="$t('pages.inventory.cards.transmitters.subTitle', {usedCount: this.transmittersUsedCount, freeCount: this.transmittersFreeCount})"></h3>
          <data-table class="mt-4" v-if="transmittersTable.data" :columns="transmittersTable.columns" :data="transmittersTable.data">
            <template v-slot:actions="{ row }">
              <router-link :to="row.actions.see.to" class="btn btn-primary">{{ row.actions.see.text }}</router-link>
              <router-link :to="row.actions.edit.to" class="btn btn-secondary">{{ row.actions.edit.text }}</router-link>
            </template>
          </data-table>
        </div>
      </div>

      <!-- All sensors -->
      <div v-if="isLoaded.sensors" class="inventory-container">
        <div class="card">
          <h2 class="card__title">
            <span class="title-text">Tous les capteurs </span>
            <span class="title-count">({{ sensorsTable.data.length }})</span>
          </h2>
          <h3 v-html="$t('pages.inventory.cards.transmitters.subTitle', {usedCount: this.sensorsUsedCount, freeCount: this.sensorsFreeCount})"></h3>
          <data-table class="mt-4" v-if="sensorsTable.data" :columns="sensorsTable.columns" :data="sensorsTable.data">
            <template v-slot:actions="{ row }">
              <router-link :to="row.actions.see.to" class="btn btn-primary">{{ row.actions.see.text }}</router-link>
              <router-link :to="row.actions.edit.to" class="btn btn-secondary">{{ row.actions.edit.text }}</router-link>
            </template>
          </data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiGetRequest, displayAlertSuccess} from "../../utils";
import {API_USERS_SENSORS_ALL, API_USERS_TRANSMITTERS_ALL} from "../../utils/constants";
import DataTable from "../../components/general/datatable/data-table";
import AddMaterialModal from "../../components/modals/addMaterialModal";

export default {
  name: "Inventory",
  components: {DataTable, AddMaterialModal},
  props: {
    userIdProps: {
      type: [Number, String],
      required: false // Only required for ADMIN users_inventory route
    }
  },
  data: function() {
    return {
      userId: this.userIdProps === undefined ? this.$store.state.user.id : this.userIdProps, // The userID of the user we want to see its inventory or the user id of the current user (it depends the case)
      isLoaded: {
        transmitters: false,
        sensors: false
      },
      transmittersTable : {
        columns: {},
        data: null
      },
      sensorsTable: {
        columns: {
          id: this.$t('words.id'),
          type: this.$t('words.type'),
          state: this.$t('words.state'),
          actions: this.$t('words.actions'),
        },
        data: null
      },
      addMaterialModal: {
        isVisible: false
      }
    }
  },
  /**
   * Define here the transmittersTable columns to be more dynamic
   */
  mounted() {
    this.transmittersTable.columns.id = this.$t('words.id')
    // Only admin can see the TTN id
    if(this.$store.getters.userIsAdmin){
      this.transmittersTable.columns.ttnId = "TTN id"
    }
    this.transmittersTable.columns.type = this.$t('words.type')
    this.transmittersTable.columns.state = this.$t('words.state')
    this.transmittersTable.columns.actions = this.$t('words.actions')

    // Load data
    this.fetchTransmitters()
    this.fetchSensors()
  },
  computed: {
    transmittersUsedCount: function(){
      return this.transmittersTable.data.filter(t => t.used).length
    },
    transmittersFreeCount: function(){
      return this.transmittersTable.data.filter(t => !t.used).length
    },
    sensorsUsedCount: function(){
      return this.sensorsTable.data.filter(s => s.used).length
    },
    sensorsFreeCount: function(){
      return this.sensorsTable.data.filter(s => !s.used).length
    }
  },
  methods: {
    /**
     * Fetch all Transmitters of the user.
     * If isSpecificUserInventoryPage is true, we load all transmitters of the user we want,
     * otherwise we load all transmitters of the current authenticated user
     */
    fetchTransmitters: function() {
      this.isLoaded.transmitters = false
      apiGetRequest(API_USERS_TRANSMITTERS_ALL(this.userId))
          .then((res) => {
            this.transmittersTable.data = res.data.map((t) => {
              t.type = `${this.$t('backend_trans_keys.'+t.typeTransKey)}`
              t.state = t.used === true ? this.$t('words.state-used') : this.$t('words.state-free')
              t.actions = {
                see: {
                  to: {name: 'transmitter_show', params:{id: t.id}},
                  text: this.$t('words.see')
                },
                edit: {
                  to: {name: 'transmitter_edit', params:{id: t.id}},
                  text: this.$t('words.edit')
                }
              }
              return t
            })
            this.isLoaded.transmitters = true
          })
    },
    /**
     * Fetch all Sensors of the user.
     * If isSpecificUserInventoryPage is true, we load all sensors of the user we want,
     * otherwise we load all sensors of the current authenticated user
     */
    fetchSensors: function() {
      this.isLoaded.sensors = false
      apiGetRequest(API_USERS_SENSORS_ALL(this.userId))
          .then((res) => {
            this.sensorsTable.data = res.data.filter(s => !s.linkedToWeatherStation).map((s) => {
              s.type = `${this.$t('backend_trans_keys.'+s.typeTransKey)}`
              s.state = s.used === true ? this.$t('words.state-used') : this.$t('words.state-free')
              s.actions = {
                see: {
                  to: {name: 'sensor_show', params:{id: s.id}},
                  text: this.$t('words.see')
                },
                edit: {
                  to: {name: 'sensor_edit', params:{id: s.id}},
                  text: this.$t('words.edit')
                }
              }
              return s
            })
            this.isLoaded.sensors = true
          })
    },
    /**
     * Toggle the add material modal
     */
    toggleAddMaterialModal: function() {
      this.addMaterialModal.isVisible = !this.addMaterialModal.isVisible
    },
    /**
     * Callback called when a new transmitter is created
     * @param transmitterId The transmitter ID that has been created
     */
    newTransmitterCreated: function(transmitterId) {
      const successMsg = this.$t('pages.inventory.addMaterialModal.forms.transmitter.success', {id: transmitterId})
      displayAlertSuccess(successMsg)
      this.fetchTransmitters()
    },
    /**
     * Callback called when a new sensor is created
     * @param sensorId The sensor ID that has been created
     */
    newSensorCreated: function(sensorId) {
      const successMsg = this.$t('pages.inventory.addMaterialModal.forms.sensor.success', {id: sensorId})
      displayAlertSuccess(successMsg)
      this.fetchSensors()
    }
  }
}
</script>

<style scoped>
/* Encadrement des inventaires émetteurs et capteurs */
.inventory-container {
  border: none; /* Supprimer toute bordure existante */
  outline: 1px solid rgba(0, 0, 0, 0.1); /* Bordure fine */
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px; /* Coins arrondis */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05); /* Ombre douce */
}

/* Aligner les sections côte à côte */
.inventory-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Espacement entre les cartes */
}

.card__title {
  display: flex;
  justify-content: space-between; /* Aligner le titre et le compte à chaque extrémité */
}

.title-text {
  font-weight: bold;
}

.title-count {
  font-size: 1.2em;
  font-weight: normal;
  color: #555;
}

</style>